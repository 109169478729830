<template>
  <b-container

    fluid
    class="risk-report"
  >
    <a
      class="link"
      @click="$router.push(`/balanco/visualizar/${data.file.id}`)"
    >
      < Voltar
    </a>

    <b-row>
      <Logo class="mb-1" />
    </b-row>

    <b-row>
      <b-col md="12">

        <b-card>
          <h4> {{ data.company.legal_tax_name }} </h4>
          <div
            style="border-bottom: 6px solid #2E2C6C;"
            class="mb-1"
          />

          <b-row class="px-1 justify-content-between">
            <div>
              <h6 class="d-inline">
                CNPJ:
              </h6>
              {{ data.company.tax_id | tax_id }}
            </div>

            <div>
              <h6 class="d-inline">
                ATIVIDADE:
              </h6>
              {{ data.company.activity }}
            </div>

            <div>
              <h6 class="d-inline">
                FUNDAÇÃO:
              </h6>
              {{ data.company.foundation_date | date }}
            </div>

            <div>
              <h6 class="d-inline">

                BALANÇO AUDITADO:
              </h6>
              <span>
                {{ data.file.audited_balance ? 'SIM' : 'NÃO' }}
              </span>

            </div>

            <div>
              <h6 class="d-inline">

                LIMITE DE CRÉDITO RECOMENDADO:
              </h6>
              <span :class="+data.score.recommended > 0 ?'text-success' : 'text-danger'">
                {{ data.score.recommended | value }}
              </span>

            </div>

          </b-row>

        </b-card>
      </b-col>
    </b-row>

    <div class="data-container">

      <div class="div7">
        <b-card style="width: 100%; height: 100%;">
          <h4> VADU </h4>
          <b-table
            :fields="[
              { key: 'type', label: '' },
              { key: 'value', label: '' }
            ]"
            :items="[
              { type: 'Recuperação Judicial', value: data.vadu.vadu.judicial_recovery },
              { type: 'Processo', value: data.vadu.vadu.process },
              { type: 'Protesto', value: data.vadu.vadu.protest },
            ]"
          >
            <template #cell(value)="currentData">
              <span v-if="(typeof currentData.item.value) === 'boolean'">
                {{ currentData.item.value ? 'Sim' : 'Não' }}
              </span>

              <span v-else>
                {{ currentData.item.value | value }}
              </span>
            </template>
          </b-table>
        </b-card>
      </div>

      <div class="div3">
        <b-card>

          <h4> Divida Ativa </h4>
          <b-table
            :fields="[
              { key: 'type', label: '' },
              { key: 'value', label: '' }
            ]"
            :items="[
              { type: 'Estadual', value: data.active_debt.state },
              { type: 'Federal', value: data.active_debt.federal },
              { type: 'Total', value: +data.active_debt.federal + +data.active_debt.state },
            ]"
          >
            <template #cell(value)="currentData">
              {{ currentData.item.value | value }}
            </template>
          </b-table>
        </b-card>
      </div>

      <div class="div2">
        <b-card>

          <h4> Rating </h4>
          <div
            style="border-bottom: 6px solid #2E2C6C;"
            class="mb-1"
          />
          <div class="d-flex justify-content-center">

            <vue-speedometer
              :class="`speedometer ${recusedByCreditPolicy ? 'denied' : ''}`"
              :width="270"
              :value="data.score.calcule > 0 ? data.score.calcule * 10 : 0"
              :current-value-text="recusedByCreditPolicy ? data.score.message : data.score.grade"
              :max-segment-labels="0"
            />
          </div>
        </b-card>

      </div>
      <div class="div6">
        <b-card>
          <SerasaSummary
            :class="`text-left`"
            :pefin="groupSerasa('PEFIN')"
            :refin="groupSerasa('REFIN')"
            :law-suit="groupSerasa('ACAO JUDICIAL')"
            :expired-debts="groupSerasa('DIVIDA VENCIDA')"
            :protest="groupSerasa('PROTESTO')"
            :has-injuction="!!serasa.find(item => item.messageDebts.hasInjuction)"
            :bad-check="!!serasa.find(item => item.badCheck.length)"
          />
        </b-card>
      </div>

      <div class="div1">
        <!-- <b-col md="11"> -->
        <b-card>

          <Report
            class="p-0"
            :data="data"
          />
        </b-card>
        <!-- </b-col> -->
      </div>

      <div class="div4">
        <b-card style="height: 378px; margin-bottom: 24px">
          <h4> SCR </h4>
          <b-table
            :fields="[
              { key: 'type', label: '' },
              { key: 'value', label: '' }
            ]"
            :items="[
              { type: 'Vencidos', value: +data.scr.scr_analyse.expired + sum(groupScr, 'expired') },
              { type: 'A Vencer', value: +data.scr.scr_analyse.due + sum(groupScr, 'due') },
              { type: 'Curto Prazo', value: +data.scr.scr_analyse.short_term_due + sum(groupScr, 'short_term_due') },
              { type: 'Médio Prazo', value: +data.scr.scr_analyse.mid_term_due + sum(groupScr, 'mid_term_due') },
              { type: 'Longo Prazo', value: +data.scr.scr_analyse.long_term_due + sum(groupScr, 'long_term_due') },
              { type: 'Prejuizo', value: +data.scr.scr_analyse.prejudice + sum(groupScr, 'prejudice') },
            ]"
          >
            <template #cell(value)="currentData">
              <span v-if="(typeof currentData.item.value) === 'boolean'">
                {{ currentData.item.value ? 'Sim' : 'Não' }}
              </span>

              <span v-else>
                {{ currentData.item.value | value }}
              </span>
            </template>
          </b-table>
        </b-card>

        <b-card
          class="mb-0"
          style="height: 100%;"
        >

          <h4> Analista </h4>
          <div
            style="border-bottom: 6px solid #2E2C6C;"
            class="mb-1"
          />
          <h6 v-if="data.log.length">
            {{ data.log[data.log.length - 1].user }}
          </h6>
        </b-card>
      </div>

      <div class="div5">
        <b-card style="height: 100%">

          <h4> Parecer Crédito </h4>
          <div
            style="border-bottom: 6px solid #2E2C6C;"
            class="mb-1"
          />

          <div
            v-if="data.message"
            class="d-flex flex-column align-items-between"
          >

            <div
              v-for="item in data.message.opinion.split('\n')"
              :key="item.id"
            >
              <h6 class="mb-0">
                {{ item }}
              </h6>
            </div>

          </div>

          <div v-else>
            <h6> Não Possui Parecer </h6>
          </div>

        </b-card>
      </div>

    </div>

  </b-container>
</template>

<script>
import {
    BTable, BRow, BCol, BCard, BContainer, BLink,
} from 'bootstrap-vue';

import VueSpeedometer from 'vue-speedometer';
import Report from '../Report/Report.vue';

import Logo from '@/components/Logo.vue';

import SerasaSummary from '../Serasa/Summary.vue';

export default {
    components: {
        BRow,
        BTable,
        BCard,
        BCol,
        Report,
        BLink,
        BContainer,
        SerasaSummary,
        Logo,
        VueSpeedometer,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    computed: {
        recusedByCreditPolicy() {
            return this.data.score.calcule < 0;
        },

        notRecommended() {
            return this.data.score.grade === 'C' || this.data.score.grade === 'D';
        },

        groupScr() {
            return this.data.group.map(item => item.scr);
        },

        groupReport() {
            return this.data.group.map(item => item.report);
        },

        serasa() {
            const arrAmount = this.data.group.map(item => item.report);

            arrAmount.push(this.data.serasa.report);

            return arrAmount;
        },
    },

    methods: {
        sum(
            arr,
            key,
        ) {
            return arr.reduce((acc, item) => acc + +item[key], 0);
        },

        groupSerasa(disc) {
            return this.serasa.reduce((acc, item) => {
                const resume = item.resumeFinancialPendency.find(resume => resume.disc === disc);

                acc.value = (acc.value || 0) + (resume?.value || 0);
                return acc;
            }, {});
        },
    },
};
</script>

<style lang="scss">
    body .risk-report {
        zoom: 90%;
    }

    .link {
        color: white;
    }
.risk-report  {

    .link {
        color: #e1e1e1;
        transition: all linear .04s;
        &:hover {
            color: #fff
        }
    }

    table {
        thead {
            background-color: #2E2C6C;
            tr th {
                background-color: transparent !important;
                color: white
            }

            tr td, th {
            padding: .3rem
        }
        }

        tbody td {
            padding: 1.1rem;
        }

    }
    padding: 1rem 2rem;
    background-color: #2E2C6C;
    border-radius: .5rem;

    .data-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(6, 258px);
        max-width: 100%;
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        max-height: 820px;

        .div1 {
            grid-area: 1/4/4/6;
             >div { height: 100%;}
            table {
                thead {
                    background-color: #2E2C6C;
                    tr th {
                        background-color: transparent !important;
                        color: white
                    }

                    tr td, th {
                    padding: .1rem
                }
                }

            tbody td {
                padding: .6rem;
            }

        }
    }
        .div2 { grid-area: 1 / 2 / 2 / 1;  > div { height: 100%;}}
        .div3 { grid-area: 2 / 1 / 2 / 2;  > div { height: 100%;}}
        .div4 { display: flex; flex-direction: column; grid-area: 2 / 3 / 4 / 4;  > div { height: 100%;}}
        .div5 { grid-area: 4 / 1 / 3 / 3;  > div { height: 100%;}}
        .div6 { grid-area: 1 / 2 / 3 / 2;  > div { height: 100%;}}
        .div7 { grid-area: 2 / 3 / 1 / 3;  > div { height: 100%;}}

    }

    @media (max-width: 1500px) {
      .data-container {
        max-height: 2000px;
        display: flex;
        flex-direction: column;
        gap: 0px;
      }
    }

    .current-value {
        font-size: 20px !important;
        color: red !important;
    }

    .denied .current-value {
        font-size: 14px !important;
    }
}

</style>
