var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"risk-report",attrs:{"fluid":""}},[_c('a',{staticClass:"link",on:{"click":function($event){return _vm.$router.push(("/balanco/visualizar/" + (_vm.data.file.id)))}}},[_vm._v(" < Voltar ")]),_c('b-row',[_c('Logo',{staticClass:"mb-1"})],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('h4',[_vm._v(" "+_vm._s(_vm.data.company.legal_tax_name)+" ")]),_c('div',{staticClass:"mb-1",staticStyle:{"border-bottom":"6px solid #2E2C6C"}}),_c('b-row',{staticClass:"px-1 justify-content-between"},[_c('div',[_c('h6',{staticClass:"d-inline"},[_vm._v(" CNPJ: ")]),_vm._v(" "+_vm._s(_vm._f("tax_id")(_vm.data.company.tax_id))+" ")]),_c('div',[_c('h6',{staticClass:"d-inline"},[_vm._v(" ATIVIDADE: ")]),_vm._v(" "+_vm._s(_vm.data.company.activity)+" ")]),_c('div',[_c('h6',{staticClass:"d-inline"},[_vm._v(" FUNDAÇÃO: ")]),_vm._v(" "+_vm._s(_vm._f("date")(_vm.data.company.foundation_date))+" ")]),_c('div',[_c('h6',{staticClass:"d-inline"},[_vm._v(" BALANÇO AUDITADO: ")]),_c('span',[_vm._v(" "+_vm._s(_vm.data.file.audited_balance ? 'SIM' : 'NÃO')+" ")])]),_c('div',[_c('h6',{staticClass:"d-inline"},[_vm._v(" LIMITE DE CRÉDITO RECOMENDADO: ")]),_c('span',{class:+_vm.data.score.recommended > 0 ?'text-success' : 'text-danger'},[_vm._v(" "+_vm._s(_vm._f("value")(_vm.data.score.recommended))+" ")])])])],1)],1)],1),_c('div',{staticClass:"data-container"},[_c('div',{staticClass:"div7"},[_c('b-card',{staticStyle:{"width":"100%","height":"100%"}},[_c('h4',[_vm._v(" VADU ")]),_c('b-table',{attrs:{"fields":[
            { key: 'type', label: '' },
            { key: 'value', label: '' }
          ],"items":[
            { type: 'Recuperação Judicial', value: _vm.data.vadu.vadu.judicial_recovery },
            { type: 'Processo', value: _vm.data.vadu.vadu.process },
            { type: 'Protesto', value: _vm.data.vadu.vadu.protest } ]},scopedSlots:_vm._u([{key:"cell(value)",fn:function(currentData){return [((typeof currentData.item.value) === 'boolean')?_c('span',[_vm._v(" "+_vm._s(currentData.item.value ? 'Sim' : 'Não')+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("value")(currentData.item.value))+" ")])]}}])})],1)],1),_c('div',{staticClass:"div3"},[_c('b-card',[_c('h4',[_vm._v(" Divida Ativa ")]),_c('b-table',{attrs:{"fields":[
            { key: 'type', label: '' },
            { key: 'value', label: '' }
          ],"items":[
            { type: 'Estadual', value: _vm.data.active_debt.state },
            { type: 'Federal', value: _vm.data.active_debt.federal },
            { type: 'Total', value: +_vm.data.active_debt.federal + +_vm.data.active_debt.state } ]},scopedSlots:_vm._u([{key:"cell(value)",fn:function(currentData){return [_vm._v(" "+_vm._s(_vm._f("value")(currentData.item.value))+" ")]}}])})],1)],1),_c('div',{staticClass:"div2"},[_c('b-card',[_c('h4',[_vm._v(" Rating ")]),_c('div',{staticClass:"mb-1",staticStyle:{"border-bottom":"6px solid #2E2C6C"}}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('vue-speedometer',{class:("speedometer " + (_vm.recusedByCreditPolicy ? 'denied' : '')),attrs:{"width":270,"value":_vm.data.score.calcule > 0 ? _vm.data.score.calcule * 10 : 0,"current-value-text":_vm.recusedByCreditPolicy ? _vm.data.score.message : _vm.data.score.grade,"max-segment-labels":0}})],1)])],1),_c('div',{staticClass:"div6"},[_c('b-card',[_c('SerasaSummary',{class:"text-left",attrs:{"pefin":_vm.groupSerasa('PEFIN'),"refin":_vm.groupSerasa('REFIN'),"law-suit":_vm.groupSerasa('ACAO JUDICIAL'),"expired-debts":_vm.groupSerasa('DIVIDA VENCIDA'),"protest":_vm.groupSerasa('PROTESTO'),"has-injuction":!!_vm.serasa.find(function (item) { return item.messageDebts.hasInjuction; }),"bad-check":!!_vm.serasa.find(function (item) { return item.badCheck.length; })}})],1)],1),_c('div',{staticClass:"div1"},[_c('b-card',[_c('Report',{staticClass:"p-0",attrs:{"data":_vm.data}})],1)],1),_c('div',{staticClass:"div4"},[_c('b-card',{staticStyle:{"height":"378px","margin-bottom":"24px"}},[_c('h4',[_vm._v(" SCR ")]),_c('b-table',{attrs:{"fields":[
            { key: 'type', label: '' },
            { key: 'value', label: '' }
          ],"items":[
            { type: 'Vencidos', value: +_vm.data.scr.scr_analyse.expired + _vm.sum(_vm.groupScr, 'expired') },
            { type: 'A Vencer', value: +_vm.data.scr.scr_analyse.due + _vm.sum(_vm.groupScr, 'due') },
            { type: 'Curto Prazo', value: +_vm.data.scr.scr_analyse.short_term_due + _vm.sum(_vm.groupScr, 'short_term_due') },
            { type: 'Médio Prazo', value: +_vm.data.scr.scr_analyse.mid_term_due + _vm.sum(_vm.groupScr, 'mid_term_due') },
            { type: 'Longo Prazo', value: +_vm.data.scr.scr_analyse.long_term_due + _vm.sum(_vm.groupScr, 'long_term_due') },
            { type: 'Prejuizo', value: +_vm.data.scr.scr_analyse.prejudice + _vm.sum(_vm.groupScr, 'prejudice') } ]},scopedSlots:_vm._u([{key:"cell(value)",fn:function(currentData){return [((typeof currentData.item.value) === 'boolean')?_c('span',[_vm._v(" "+_vm._s(currentData.item.value ? 'Sim' : 'Não')+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("value")(currentData.item.value))+" ")])]}}])})],1),_c('b-card',{staticClass:"mb-0",staticStyle:{"height":"100%"}},[_c('h4',[_vm._v(" Analista ")]),_c('div',{staticClass:"mb-1",staticStyle:{"border-bottom":"6px solid #2E2C6C"}}),(_vm.data.log.length)?_c('h6',[_vm._v(" "+_vm._s(_vm.data.log[_vm.data.log.length - 1].user)+" ")]):_vm._e()])],1),_c('div',{staticClass:"div5"},[_c('b-card',{staticStyle:{"height":"100%"}},[_c('h4',[_vm._v(" Parecer Crédito ")]),_c('div',{staticClass:"mb-1",staticStyle:{"border-bottom":"6px solid #2E2C6C"}}),(_vm.data.message)?_c('div',{staticClass:"d-flex flex-column align-items-between"},_vm._l((_vm.data.message.opinion.split('\n')),function(item){return _c('div',{key:item.id},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item)+" ")])])}),0):_c('div',[_c('h6',[_vm._v(" Não Possui Parecer ")])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }