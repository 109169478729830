<template>
  <b-container fluid>

    <summary-view
      v-if="data"
      :data="data"
    />
  </b-container>
</template>

<script>
import { BContainer } from 'bootstrap-vue';
import SummaryView from '../ViewBalance/components/Summary/Summary.vue';

import BalanceService from '@/service/balance/';

export default {
    components: {
        BContainer,
        SummaryView,
    },

    data() {
        return {
            data: null,
        };
    },

    mounted() {
        this.getData();
    },

    methods: {
        async getData() {
            const { id } = this.$route.params;
            const { data } = await BalanceService.viewBalances(id);
            this.data = data;
        },
    },
};
</script>
