<template>
  <b-img
    class=""
    v-bind="mainProps"
    width="200"
    src="@/assets/images/logo/logo_grupovia_white.png"
  />
</template>

<script>
import { BImg } from 'bootstrap-vue';

export default {
    components: {
        BImg,
    },

    data() {
        return {

            mainProps: { class: 'm2' },
        };
    },
};
</script>
